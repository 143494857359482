import { Button } from '@chakra-ui/react';
import {
  DataType,
  PropertyValue,
  RpgConfigAiGeneration,
  StaticValue,
  ValueType,
} from '@common/studio-types';
import { Field, useMaestroToast } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useStudioRpgConfig } from '../../../../hooks/useStudioRpgConfig';
import { Hint } from '../../../Hint';
import { StaticValueInput } from '../../../value/StaticValueInput';
import { useGenerateAiEpisode } from './useGenerateAiEpisode';

export const EpisodeGenerationForm: React.FC = () => {
  const { rpgConfig, findProperty } = useStudioRpgConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<Record<string, string | number>>({
    //   'f0545c4b-dc04-4576-96ae-9a7a29dffdf0': 'Pirate and the Seas',
    //   '842bc496-2bdc-436e-9099-ba3d1432d1cb': 'An adventure to defeat Captain Sneakybeard',
    //   'd5942870-9d00-464f-9eb2-7314da051b87': 'Captain Sneakybeard',
    //   'dff1062f-0cfb-42c1-a3e6-76a34fc465cf': 'Peter',
    //   '45836682-6487-40e5-a3c3-557f1c5a5bce': '8',
    //   '61f40385-8f03-440b-b861-a838779381a2': 'ded6c046-62db-47bb-94b0-69e848e63bd1',
    //   '49828449-becb-405c-b037-65bb24b2d823': 'Barnacle',
    //   '26a07467-6487-4b9f-a264-d735932ddc27': 'Cat',
  });

  const aiGeneration = rpgConfig.aiGeneration ?? [];
  const generateAiEpisode = useGenerateAiEpisode();
  const toast = useMaestroToast();
  const onApply = async () => {
    const newState: Record<string, string | number> = {};

    Object.keys(state).forEach((key) => {
      const property = findProperty(key);

      if (property) {
        if (property.config.dataType === DataType.Enum) {
          newState[property.name] =
            property.config.options.find((option) => option.ref === state[key])
              ?.label ?? '';
        } else {
          newState[property.name] = state[key];
        }
      }
    });

    setIsLoading(true);

    try {
      await generateAiEpisode(newState);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast({
        status: 'warning',
        title: 'Failed to generate episode',
        description: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setStaticValue = (
    staticValue: StaticValue,
    field: RpgConfigAiGeneration,
  ) => {
    const property = findProperty((field.value as PropertyValue).ref);

    if (property) {
      const value =
        staticValue.dataType === DataType.Enum
          ? staticValue.ref
          : staticValue.value;

      setState({ ...state, [property.id]: value });
    }
  };

  const getValue = (field: RpgConfigAiGeneration): StaticValue => {
    const property = findProperty((field.value as PropertyValue).ref);

    if (!property) {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.String,
        value: '',
      };
    }

    const value = state[property.id];

    if (property.config.dataType === DataType.Enum) {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.Enum,
        ref: (value as string) ?? '',
        enumRef: property.config.enumRef,
      };
    } else if (property.config.dataType === DataType.String) {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.String,
        value: (value as string) ?? '',
      };
    } else {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.Number,
        value: (value as number) ?? 0,
      };
    }
  };

  return (
    <Container>
      <FormContainer>
        {aiGeneration.map((field) => (
          <StyledField label={field.displayName ?? ''} key={field.id}>
            {field.description && <StyledHint>{field.description}</StyledHint>}
            <StaticValueInput
              onChange={(value) => setStaticValue(value, field)}
              value={getValue(field)}
              rpgConfig={rpgConfig}
            />
          </StyledField>
        ))}
      </FormContainer>

      <ButtonGroup>
        <Button
          variant="primary"
          width="100%"
          onClick={onApply}
          isLoading={isLoading}
        >
          {isLoading ? 'Generating' : 'Generate'}
        </Button>
      </ButtonGroup>
    </Container>
  );
};

const StyledHint = styled(Hint)`
  margin: ${dimensions.size0};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.base.dark['1000']};
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${dimensions.size16};
  gap: ${dimensions.size8};
  width: 100%;
  overflow: auto;
`;

const StyledField = styled(Field)`
  margin-bottom: ${dimensions.size0};
  flex: 0;
`;

const ButtonGroup = styled.div`
  ${textStyles.label.lb16sb};
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size16};
  cursor: pointer;
  text-align: center;

  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default['100']};
`;
