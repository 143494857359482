import { Button, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Icon, Modal, ModalBody } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import { EpisodeGenerationForm } from './EpisodeGenerationForm';

export const EpisodeGenerationModal: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isAiTemplateEpisodes = useFeatureFlag(FeatureFlags.AiTemplateEpisodes);

  if (!isAiTemplateEpisodes) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Generate new episode with AI"
      >
        <ModalBody>
          <EpisodeGenerationForm />
        </ModalBody>
      </Modal>
      <Tooltip label="Generate episode from AI" placement="top">
        <Button
          onClick={onOpen}
          variant="default"
          size="sm"
          fontSize={dimensions.size12}
          lineHeight="14px"
          borderRadius={dimensions.size8}
          leftIcon={<Icon name="edit-with-ai" size={rawDimensions.size16} />}
          // Necessary to prevent tooltip from showing up
          // @see https://github.com/chakra-ui/chakra-ui/issues/5304
          onFocus={(e) => e.preventDefault()}
        >
          Generate new episode
        </Button>
      </Tooltip>
    </>
  );
};
